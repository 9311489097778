import React from "react";
import { StatusIndicator, Text } from "@withjuly/solis";
import { cx } from "@withjuly/frontend-common";
import { useRouter } from "next/router";

interface NavItemProps {
	name: string;
	icon: React.ReactNode;
	selected: boolean;
	onClick?: () => void;
	href?: string;
	prefetch?: () => void;
	isNew?: boolean;
	isHidden?: boolean;
}

export const NavItem: React.FC<NavItemProps> = ({
	name,
	icon,
	selected,
	href,
	prefetch,
	onClick,
	isNew,
	isHidden,
}) => {
	const router = useRouter();

	if (isHidden) {
		return null;
	}

	return (
		<button
			role="link"
			onClick={() => {
				if (onClick) {
					onClick();
				} else if (href) {
					router.push(href);
				}
			}}
			onMouseEnter={prefetch}
			className={cx(
				"hover:text-white-200 flex h-9 w-full cursor-pointer items-center justify-start rounded-lg px-[13px] py-3 font-bold ease-out hover:bg-gray-700 focus:shadow-none active:scale-[0.98]",
				"transition-all duration-150 ease-in-out",
				selected ? "text-white-100 bg-gray-500" : "text-gray-100",
			)}
		>
			<div
				className={cx(
					"hover:text-white-200",
					selected ? "text-white-100" : "text-gray-100",
				)}
			>
				{icon}
			</div>
			<Text
				variant="bold/lg"
				className="ml-4 text-inherit"
				style={{ textDecoration: "none" }}
			>
				{name}
			</Text>
			{isNew ? (
				<div className="ml-auto flex items-center">
					<StatusIndicator
						status="brand"
						label="New"
						size="small"
						fill={false}
					/>
				</div>
			) : null}
		</button>
	);
};
