export const helpCenterItems = {
	overview: {
		name: "July Overview",
		link: "https://www.youtube.com/embed/XxnTMzl6bUk",
	},
	discover: {
		name: "Discover",
		link: "https://www.youtube.com/embed/iitFBE7fkfI",
	},
	dealflow: {
		name: "Dealflow",
		link: "https://www.youtube.com/embed/0b8s309XDmM",
	},
	templates: {
		name: "Templates",
		link: "https://www.youtube.com/embed/B53qZktxwLE",
	},
	payments: {
		name: "Invoices",
		link: "https://www.youtube.com/embed/WxFFPdDd6sg",
	},
	contracts: {
		name: "Contracts",
		link: "https://www.youtube.com/embed/C99DL3rV2b8",
	},
	rate_calculator: {
		name: "Rate Calculator",
		link: "https://www.youtube.com/embed/A_spg-ks0XM",
	},
	mediakit: {
		name: "Media Kit",
		link: "https://www.youtube.com/embed/an-Wy-k70sY",
	},
} as const;

export type HelpCenterPage = keyof typeof helpCenterItems;
