import { Close, Question } from "@withjuly/julycons";
import Link from "next/link";
import {
	Text,
	Avatar,
	AvatarFallback,
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuItem,
} from "@withjuly/solis";
import { useRouter } from "next/router";
import { useAuth } from "~/utils/context/auth";

export interface HeaderActionsProps {
	setIsHelpCenterOpen: (isOpen: boolean) => void;
	isHelpCenterTooltipOpen?: boolean;
	setIsHelpCenterTooltipOpen?: (isOpen: boolean) => void;
}

export const HeaderActions: React.FC<HeaderActionsProps> = ({
	setIsHelpCenterOpen,
	isHelpCenterTooltipOpen,
	setIsHelpCenterTooltipOpen,
}) => {
	const { user } = useAuth();
	const router = useRouter();
	const menuLinks = [
		{ label: "General", link: "/settings/general" },
		{ label: "Brand Preferences", link: "/settings/brands" },
		{ label: "Handles", link: "/settings/handles" },
		{ label: "Email", link: "/settings/email" },
		{ label: "Billing", link: "/settings/billing" },
	];

	return (
		<div className="flex items-center gap-4">
			<div className="relative flex">
				<button
					className="flex gap-2 whitespace-nowrap rounded text-gray-100 transition-all duration-150 ease-in-out"
					onClick={() => {
						setIsHelpCenterOpen(true);
						setIsHelpCenterTooltipOpen?.(false);
					}}
				>
					<Question />
					<Text variant="body/sm">Help Center</Text>
				</button>
				{isHelpCenterTooltipOpen && (
					<div className="absolute right-0 top-full mt-4 flex w-[15.5rem] items-center gap-4 rounded-lg bg-blue-300 p-4 text-gray-900 after:absolute after:-top-[.375rem] after:right-4 after:h-3 after:w-3 after:rotate-45 after:bg-blue-300 lg:w-auto">
						<Text className="w-full lg:whitespace-nowrap">
							For tutorials, FAQ, and support, visit the Help Center.
						</Text>
						<button
							className="flex cursor-pointer"
							onClick={() => setIsHelpCenterTooltipOpen?.(false)}
						>
							<Close aria-label="Close" />
						</button>
					</div>
				)}
			</div>

			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Avatar>
						<AvatarFallback>{`${user?.firstName[0] ?? ""}${
							user?.lastName[0] ?? ""
						}`}</AvatarFallback>
					</Avatar>
				</DropdownMenuTrigger>
				<DropdownMenuContent
					className="min-w-[15rem] origin-top-right"
					align="end"
				>
					<div className="flex items-center gap-3 border-b border-gray-500 p-3">
						<Avatar>
							<AvatarFallback>
								{`${user?.firstName[0] ?? ""}${user?.lastName[0] ?? ""}`}
							</AvatarFallback>
						</Avatar>
						<div className="flex flex-col">
							<Text>
								{user?.firstName} {user?.lastName}
							</Text>
							<Text variant="body/xs" className="text-gray-100">
								{user?.email}
							</Text>
						</div>
					</div>
					{menuLinks.map(({ label, link }) => (
						<Link key={link} href={link} style={{ textDecoration: "none" }}>
							<DropdownMenuItem>{label}</DropdownMenuItem>
						</Link>
					))}
					<DropdownMenuItem
						onClick={() => router.push("/signout")}
						className="border-t border-gray-500"
					>
						Sign out
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	);
};
