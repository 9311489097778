import BetaLogo from "~/assets/BetaLogo.svg";
import { HeaderActions } from "./HeaderActions";
import { MobileMenu } from "@withjuly/julycons";
import Link from "next/link";
import { useState } from "react";
import { MobileSideMenu } from "./MobileSideMenu";

interface MobileHeaderProps {
	setIsHelpCenterOpen: (isOpen: boolean) => void;
	setIsHelpCenterTooltipOpen?: (isOpen: boolean) => void;
	isHelpCenterTooltipOpen?: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({
	setIsHelpCenterOpen,
	isHelpCenterTooltipOpen,
	setIsHelpCenterTooltipOpen,
}) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<div className="flex h-full items-center justify-between px-8 lg:hidden">
			<div className="flex items-center gap-3">
				<button
					className="rounded transition-all duration-150 ease-in-out"
					onClick={() => setIsMenuOpen(true)}
				>
					<MobileMenu aria-label="open menu" />
				</button>

				<Link
					href="/discover"
					className="rounded transition-all duration-150 ease-in-out"
				>
					<img src={BetaLogo.src} alt="July" className="h-6 w-auto" />
				</Link>
			</div>

			<HeaderActions
				setIsHelpCenterOpen={setIsHelpCenterOpen}
				isHelpCenterTooltipOpen={isHelpCenterTooltipOpen}
				setIsHelpCenterTooltipOpen={setIsHelpCenterTooltipOpen}
			/>

			<MobileSideMenu
				isOpen={isMenuOpen}
				setIsOpen={setIsMenuOpen}
				setIsHelpCenterOpen={setIsHelpCenterOpen}
			/>
		</div>
	);
};
