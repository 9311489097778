import { cx } from "@withjuly/frontend-common";
import { ArrowRight, Discord } from "@withjuly/julycons";
import {
	Button,
	ModalBody,
	ModalDescription,
	ModalHeader,
	ModalTitle,
	Text,
} from "@withjuly/solis";
import Link from "next/link";
import { useState } from "react";
import { helpCenterItems, HelpCenterPage } from "./constants";

interface HelpCenterModalProps {
	setIsOpen: (isOpen: boolean) => void;
}

export const HelpCenterModal: React.FC<HelpCenterModalProps> = ({
	setIsOpen,
}) => {
	const [currentVideo, setCurrentVideo] = useState<HelpCenterPage>("overview");

	return (
		<>
			<ModalHeader>
				<ModalTitle>
					<Text className="text-h6 md:text-h4">Help Center</Text>
				</ModalTitle>
				<ModalDescription asChild>
					<Text variant="body/lg" className="inline-block text-gray-100">
						Want to learn even more about how July works? Go to the
						<Link
							className="border-white-100 text-white-100 ml-1 inline-flex items-center gap-1 border-b text-sm font-semibold"
							href="https://withjuly.notion.site/Welcome-to-July-s-Knowledge-Base-0cd768b001224da78608b6202d67cf20"
							target="_blank"
							rel="noreferrer"
						>
							July Knowledge Base
							<ArrowRight className="text-xs" />
						</Link>
					</Text>
				</ModalDescription>
			</ModalHeader>
			<ModalBody>
				<div className="grid max-h-80 w-full grid-cols-[1fr] gap-4 overflow-y-scroll md:max-h-full md:grid-cols-[9.25rem_1fr] md:overflow-y-auto">
					<div className="order-2 flex flex-col gap-1 md:order-1">
						{Object.keys(helpCenterItems).map((key) => {
							const page = key as HelpCenterPage;

							return (
								<button
									key={key}
									className={cx(
										"rounded-lg p-2 text-left",
										currentVideo === key && "bg-gray-300",
										"transition-all duration-150 ease-in-out",
									)}
									onClick={() => setCurrentVideo(page)}
								>
									<Text variant="body/lg" className="text-white-100">
										{helpCenterItems[page].name}
									</Text>
								</button>
							);
						})}
					</div>
					<div className="order-1 h-48 w-full overflow-hidden rounded-md md:order-2 md:h-[20.25rem]">
						<iframe
							width="100%"
							height="100%"
							src={helpCenterItems[currentVideo].link}
							title="YouTube video player"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
					</div>
				</div>
				<div className="mt-6 flex w-full flex-col items-start justify-between gap-2 border-t border-gray-300 pt-6 md:flex-row md:items-center">
					<a
						href="https://discord.gg/Y22vtGrVNW"
						target="_blank"
						rel="noreferrer"
						className="w-full md:w-auto"
					>
						<Button variant="outline" size="huge" className="w-full md:w-auto">
							<span className="flex items-center gap-2">
								<Discord className="text-white-100" /> Join our Creator Discord
							</span>
						</Button>
					</a>
					<div className="flex w-full flex-col gap-3 md:w-auto md:flex-row">
						<Button variant="outline" size="huge">
							<Link
								href="https://calendly.com/d/dy9-d4q-vjf/july-early-access"
								target="_blank"
								rel="noreferrer"
							>
								Sign up for an Onboarding
							</Link>
						</Button>
						<Button
							variant="primary"
							size="huge"
							onClick={() => setIsOpen(false)}
							className="launch_intercom"
						>
							Contact Support
						</Button>
					</div>
				</div>
			</ModalBody>
		</>
	);
};
