import {
	Calculator,
	Chat,
	InboxFull,
	Money,
	Pitch,
	User,
} from "@withjuly/julycons";
import { NextRouter } from "next/router";
import { compact } from "./util";

export interface SidebarLink {
	name: string;
	onClick: () => void;
	href?: string;
	icon: React.ReactNode;
	prefetch?: () => void;
	isNew?: boolean;
}

export const useLinks = (router: NextRouter): SidebarLink[] => {
	return compact([
		{
			name: "Discover",
			onClick: () => router.push("/discover"),
			href: "/discover",
			icon: <Pitch />,
		},
		{
			name: "Dealflow",
			onClick: () => router.push("/dealflow"),
			href: "/dealflow",
			icon: <InboxFull />,
		},
		{
			name: "Templates",
			onClick: () => router.push("/templates"),
			href: "/templates",
			icon: <Chat />,
		},
		{
			name: "Invoicing",
			onClick: () => router.push("/payments"),
			href: "/payments",
			icon: <Money />,
		},
		{
			name: "Rate Calculator",
			onClick: () => router.push("/pricing-calculator"),
			href: "/pricing-calculator",
			icon: <Calculator />,
		},
		{
			name: "Media Kit",
			onClick: () => router.push("/mediakit"),
			href: "/mediakit",
			icon: <User />,
		},
	]);
};
