import React from "react";
import { Item, Select, Text } from "@withjuly/solis";
import { useCreator } from "~/utils/context/creator";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { trpc } from "../Utility/trpc";
import { User } from "@withjuly/julycons";
import posthog from "posthog-js";
import { useAuth } from "~/utils/context/auth";

export const AccountSwitcher = () => {
	const utils = trpc.useContext();
	const { user } = useAuth();
	const { availableCreatorProfiles, creatorProfile, setCreatorProfileUuid } =
		useCreator();
	const isManagerAccessEnabled = useFeatureFlagEnabled("manager-access");
	const showAccountSwitcher =
		isManagerAccessEnabled === true && availableCreatorProfiles.length > 1;

	if (!showAccountSwitcher) {
		return null;
	}

	return (
		<Select
			className="border-none"
			contentClassName="border-none"
			value={creatorProfile?.uuid}
			setValue={(uuid) => {
				utils.invalidate();
				setCreatorProfileUuid(uuid);

				posthog.capture("Manager Switch Account", {
					toOwnProfile: uuid === user?.creatorProfile?.uuid,
				});
			}}
			icon={<User />}
		>
			{availableCreatorProfiles?.map((profile) => (
				<Item key={profile.uuid} value={profile.uuid}>
					<div className="flex flex-col items-start">
						<Text variant="body/sm">
							{profile.user.firstName} {profile.user.lastName}
						</Text>
						<Text variant="body/xs" className="text-gray-200">
							{profile.user.email}
						</Text>
					</div>
				</Item>
			))}
		</Select>
	);
};
