import { Gift } from "@withjuly/julycons";
import { Text } from "@withjuly/solis";
import Link from "next/link";

export const ReferralButton: React.FC = () => {
	return (
		<>
			<Link
				href="/invite"
				className="flex w-full items-center justify-center gap-2 rounded-lg border border-blue-500 bg-gray-500 p-3 text-sm font-semibold transition-all duration-150 ease-in-out hover:bg-blue-500 hover:text-gray-700"
			>
				<Gift />
				<Text variant="bold/sm">Earn $500</Text>
			</Link>
		</>
	);
};
