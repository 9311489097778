import React, { useState } from "react";
import { useRouter } from "next/router";
import { useLinks } from "./UseLinks";
import { NavItem } from "./NavItem";
import { Close, Question, Settings } from "@withjuly/julycons";
import { cx } from "@withjuly/frontend-common";
import { Text, Tooltip, TooltipContent, TooltipTrigger } from "@withjuly/solis";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { trpc } from "~/components/Utility/trpc";
import { ReferralButton } from "./ReferralButton";
import { MdLogout } from "react-icons/md";
import { UpgradeModal } from "~/components/Payment/UpgradeModal";
import { useCreator } from "~/utils/context/creator";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { AccountSwitcher } from "~/components/Settings/AccountSwitcher";

dayjs.extend(relativeTime);

export interface MenuProps {
	setIsHelpCenterOpen?: (isOpen: boolean) => void;
	isHelpCenterTooltipOpen?: boolean;
	setIsHelpCenterTooltipOpen?: (isOpen: boolean) => void;
	onLinkClick?: () => void;
}

export const Menu: React.FC<MenuProps> = ({
	setIsHelpCenterOpen,
	isHelpCenterTooltipOpen,
	setIsHelpCenterTooltipOpen,
	onLinkClick,
}) => {
	const router = useRouter();
	const links = useLinks(router);
	const { data: billing } = trpc.billing.get.useQuery();

	const { availableCreatorProfiles } = useCreator();
	const isManagerAccessEnabled = useFeatureFlagEnabled("manager-access");
	const showAccountSwitcher =
		isManagerAccessEnabled === true && availableCreatorProfiles.length > 1;

	return (
		<div className="flex w-full flex-grow flex-col justify-between gap-4 p-4">
			<div className="flex flex-col items-center gap-2">
				{links.map((link) => (
					<NavItem
						key={link.name}
						name={link.name}
						icon={link.icon}
						onClick={() => {
							onLinkClick?.();
							link.onClick();
						}}
						href={link.href}
						selected={router.pathname.startsWith(link.href || "-1")}
						prefetch={link.prefetch}
						isNew={link.isNew}
					/>
				))}
			</div>
			<div className="flex flex-col">
				<div className="hidden flex-col gap-4 px-2 lg:flex">
					<div className="relative flex">
						<button
							className="flex items-center gap-2 whitespace-nowrap rounded text-gray-200 transition-all duration-150 ease-in-out hover:text-gray-100"
							onClick={() => {
								onLinkClick?.();
								setIsHelpCenterOpen?.(true);
								setIsHelpCenterTooltipOpen?.(false);
							}}
						>
							<Question />
							<Text>Help Center</Text>
						</button>
						{isHelpCenterTooltipOpen ? (
							<div className="absolute right-0 top-full z-50 mt-4 flex w-[15.5rem] items-center gap-4 rounded-lg bg-blue-300 p-4 text-gray-900 after:absolute after:-top-[.375rem] after:right-4 after:h-3 after:w-3 after:rotate-45 after:bg-blue-300 lg:left-0 lg:right-[initial] lg:w-auto lg:after:left-4">
								<Text className="w-full lg:whitespace-nowrap">
									For tutorials, FAQ, and support, visit the Help Center.
								</Text>
								<button
									className="flex cursor-pointer"
									onClick={() => setIsHelpCenterTooltipOpen?.(false)}
								>
									<Close aria-label="Close" />
								</button>
							</div>
						) : null}
					</div>

					<div className="flex items-center justify-between gap-2">
						<button
							className="flex items-center gap-2 rounded text-gray-200 transition-all duration-150 ease-in-out hover:text-gray-100"
							onClick={() => {
								onLinkClick?.();
								router.push("/settings/general");
							}}
						>
							<Settings />
							<Text>Account Settings</Text>
						</button>

						<Tooltip>
							<TooltipTrigger asChild>
								<button
									className="flex items-center gap-2 rounded text-gray-200 transition-all duration-150 ease-in-out hover:text-gray-100"
									onClick={() => {
										onLinkClick?.();
										router.push("/signout");
									}}
								>
									<MdLogout />
								</button>
							</TooltipTrigger>
							<TooltipContent side="top">Sign out</TooltipContent>
						</Tooltip>
					</div>
				</div>

				<span className="mb-2 mt-4 hidden h-[1px] bg-gray-500 lg:flex" />

				<PlanInfo />

				<div
					className={cx(
						!(billing?.status === "trialing" || billing?.plan === "free") &&
							"mt-4",
					)}
				>
					{showAccountSwitcher ? <AccountSwitcher /> : <ReferralButton />}
				</div>
			</div>
		</div>
	);
};

const PlanInfo = () => {
	const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
	const { data: billing } = trpc.billing.get.useQuery();

	if (billing?.status === "trialing") {
		return (
			<>
				<button
					className="mb-2 flex cursor-pointer items-center justify-between gap-2 rounded-lg p-2 text-gray-100 transition-all hover:bg-gray-700"
					onClick={() => setIsUpgradeModalOpen(true)}
				>
					<div className="flex items-center gap-2">
						⏳ <Text variant="bold/lg">Free Trial</Text>
					</div>
					<Text className="text-gray-100">
						{dayjs(Date.now()).to(billing?.periodEnds, true)} left
					</Text>
				</button>

				<UpgradeModal
					isOpen={isUpgradeModalOpen}
					setIsOpen={setIsUpgradeModalOpen}
				/>
			</>
		);
	} else if (billing?.plan === "free") {
		return (
			<>
				<button
					className="mb-2 flex cursor-pointer items-center justify-between gap-2 rounded-lg p-2 text-gray-100 transition-all hover:bg-gray-700"
					onClick={() => setIsUpgradeModalOpen(true)}
				>
					<div className="flex items-center gap-2">
						💸 <Text variant="bold/lg">Upgrade to Career</Text>
					</div>
				</button>

				<UpgradeModal
					isOpen={isUpgradeModalOpen}
					setIsOpen={setIsUpgradeModalOpen}
				/>
			</>
		);
	} else {
		return null;
	}
};
