import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useAgency } from "~/utils/context/agency";
import { Add, Settings } from "@withjuly/julycons";
import { InviteTalentModal } from "~/components/Agency/InviteTalentModal";
import { useCreator } from "~/utils/context/creator";
import { useRouter } from "next/router";
import { cx } from "@withjuly/frontend-common";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@withjuly/solis";
import { useAuth } from "~/utils/context/auth";
import { MdLogout } from "react-icons/md";

interface CheckState {
	invite: boolean;
	auth: boolean;
	exclusive: boolean | undefined;
}

export const AgencySwitcher = ({
	alwaysRender,
}: {
	alwaysRender?: boolean;
}) => {
	const router = useRouter();
	const { user } = useAuth();
	const { agencyProfile } = useAgency();
	const { setCreatorProfileUuid, creatorProfile } = useCreator();
	const [isInviting, setIsInviting] = useState(false);
	const [isAlreadyChecked, setIsAlreadyChecked] = useState<CheckState>({
		invite: false,
		auth: true,
		exclusive: undefined,
	});

	const isAgencyPath = router.pathname.startsWith("/agency");

	const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>();

	useEffect(() => {
		if (timeoutId) {
			return () => {
				clearTimeout(timeoutId);
			};
		}
	}, [timeoutId]);

	const setTimer = () => {
		const timeoutId = setTimeout(() => {
			setIsInviting(true);
		}, 1000);
		setTimeoutId(timeoutId);
	};

	if (!agencyProfile && !alwaysRender) {
		return null;
	}

	return (
		<div className="flex flex-col items-center border-r border-gray-500">
			<Tooltip>
				<TooltipTrigger asChild>
					<div className="flex w-full items-center justify-center bg-gray-700 py-4">
						<Link
							href="/agency"
							className={cx(
								"text-bold-xl relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-xl border-2 uppercase",
								isAgencyPath && "border-blue-500",
							)}
						>
							{agencyProfile?.logoUrl ? (
								<Image
									fill
									src={agencyProfile.logoUrl}
									className="pointer-events-none object-cover object-center"
									alt=""
								/>
							) : (
								agencyProfile?.name[0]
							)}
						</Link>
					</div>
				</TooltipTrigger>
				<TooltipContent side="right">{agencyProfile?.name}</TooltipContent>
			</Tooltip>

			<div className="flex h-full max-h-full w-16 flex-col items-center justify-between gap-4 overflow-y-scroll px-6 pb-4 pt-3">
				<div className="flex flex-col gap-4">
					{agencyProfile?.creators
						.sort((a, b) =>
							a.user.firstName
								.toLowerCase()
								.localeCompare(b.user.firstName.toLowerCase()),
						)
						.map((c) => (
							<Tooltip key={c.uuid}>
								<TooltipTrigger asChild>
									<button
										className={cx(
											"text-bold-xl relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-full border-2 uppercase transition-all duration-200 ease-in-out",
											creatorProfile?.uuid === c.uuid &&
												!isAgencyPath &&
												"border-blue-500",
										)}
										onClick={() => {
											setCreatorProfileUuid(c.uuid);
											if (isAgencyPath) {
												router.push("/mediakit");
											}
										}}
									>
										{c.user.profilePicture?.url ? (
											<Image
												fill
												src={c.user.profilePicture.url}
												className="pointer-events-none object-cover object-center"
												alt=""
											/>
										) : (
											c.user.firstName[0]
										)}
									</button>
								</TooltipTrigger>
								<TooltipContent side="right">
									{c.user.firstName} {c.user.lastName}
								</TooltipContent>
							</Tooltip>
						))}

					<Tooltip>
						<TooltipTrigger asChild>
							<button
								className="group flex h-10 w-10 transform items-center justify-center rounded-full border-2 border-transparent bg-gray-700 transition-all delay-100 duration-200 ease-in-out hover:border-blue-500 hover:bg-gray-600"
								onClick={() => setIsInviting(true)}
							>
								<Add className="h-3 w-3 text-gray-300 transition-all duration-200 ease-in-out group-hover:text-blue-500" />
							</button>
						</TooltipTrigger>
						<TooltipContent side="right">Invite talent</TooltipContent>
					</Tooltip>

					<InviteTalentModal
						isOpen={isInviting}
						setIsOpen={setIsInviting}
						setTimer={setTimer}
						alreadyChecked={isAlreadyChecked}
						continueChecked={setIsAlreadyChecked}
					/>
				</div>

				<div>
					<DropdownMenu>
						<DropdownMenuTrigger className="text-body-sm flex h-8 w-8 items-center justify-center rounded-lg bg-gray-400 uppercase transition-all hover:bg-gray-300">
							{user?.firstName[0]}
							{user?.lastName[0]}
						</DropdownMenuTrigger>
						<DropdownMenuContent
							side="right"
							align="end"
							className="origin-bottom-left cursor-pointer rounded-[4px] p-1"
						>
							<DropdownMenuItem
								className="flex items-center gap-2 rounded-[4px]"
								onClick={() => router.push("/agency/personal-settings")}
							>
								<Settings /> Your Settings
							</DropdownMenuItem>
							<DropdownMenuItem
								className="flex cursor-pointer items-center gap-2 rounded-[4px]"
								onSelect={() => router.push("/signout")}
							>
								<MdLogout /> Sign out
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			</div>
		</div>
	);
};
