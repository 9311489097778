import BetaLogo from "~/assets/BetaLogo.svg";
import { Close } from "@withjuly/julycons";
import { Drawer } from "@withjuly/solis";
import Link from "next/link";
import { Menu } from "./Menu";

export interface MobileSideMenuProps {
	setIsHelpCenterOpen?: (isOpen: boolean) => void;
	setIsOpen: (isOpen: boolean) => void;
	isOpen: boolean;
}

export const MobileSideMenu: React.FC<MobileSideMenuProps> = ({
	setIsHelpCenterOpen,
	setIsOpen,
	isOpen,
}) => {
	return (
		<Drawer
			setIsOpen={setIsOpen}
			isOpen={isOpen}
			placement="left"
			content={
				<div className="flex h-full flex-col items-start bg-gray-800 lg:hidden">
					<div className="flex w-full justify-between p-6 pb-0">
						<Link
							href="/discover"
							onClick={() => setIsOpen(false)}
							className="rounded transition-all duration-150 ease-in-out"
						>
							<img className="h-6 w-auto" src={BetaLogo.src} alt="July" />
						</Link>
						<button
							className="flex rounded text-gray-100 transition-all duration-150 ease-in-out"
							onClick={() => setIsOpen(false)}
						>
							<Close />
						</button>
					</div>

					<Menu
						onLinkClick={() => setIsOpen(false)}
						setIsHelpCenterOpen={setIsHelpCenterOpen}
					/>
				</div>
			}
		/>
	);
};
